import * as React from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"


const TaxForms = ({data}) => {
  const states = data.allStatetaxforms2021Csv.group;

  return (
    <Layout>
      <SEO title="State Tax Forms"/>
      <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb">
                <div className="breadcrumb">
                  <div className="breadcrumb-item"><Link to="/">Home</Link></div>
                  <div className="breadcrumb-item active" aria-current="page">State Tax Forms</div>
                </div>
              </nav>
            </div>
          </div>
      </div>
      <div className="container ">
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-8">
            <h1 className="hero-heading">State Tax Forms</h1>
            <table className="table table-hover">
              <thead>
                <tr>
                <th scope="col" className="pl-3">State</th>
                <th scope="col">Forms Available</th>
                </tr>
              </thead>
              <tbody>
            {states.map(stateGroup => {
              const { state } = stateGroup.nodes[1];
              const totalCount = stateGroup.totalCount;
              const link = "/tax-forms/" + state.replace(/\s/g, '-').toLowerCase();

              return (
                <tr>
                  <td className="pl-3"><Link to={link}>{state}</Link></td>
                  <td><Link to={link}>{totalCount}</Link></td>
                </tr>
              )
            })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  )
};
export default TaxForms


export const pageQuery = graphql`
  query {
    allStatetaxforms2021Csv {
      group(field: state) {
        nodes {
          form_name
          state
        }
        totalCount
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
